import { Container, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC } from 'react';
import ZHeading from '../components/ZHeading';
import ZSEO from '../components/ZSeo';
import ZFooter from '../components/ZFooter';
import { Divider } from '@material-ui/core';
export interface QuestionProps {
  question: string;
  answer: string;
}

const PageContactUs: FC = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1600) {
            originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const services: QuestionProps[] = [
    { question: 'What services do you offer?', answer: 'Content and Copywriting, Content Marketing, Resume Writing and Proofreading. ' },
    { question: 'What is your turnaround time?', answer: 'Turnaround periods are discussed and agreed upon with each client. Expedited services are available.' },
    { question: 'How do I receive my document?', answer: 'All documents are emailed.' }
  ];
  const payment: QuestionProps[] = [
    { question: 'What are your service rates?', answer: 'Cost varies with each writing service. However, standard rates and official quotations are available upon request.' },
    {
      question: 'What payment methods do you accept?',
      answer:
        'Electronic payments are accepted. Electronic transfers may be processed via Paypal for international clients,or made out to Scotiabank or National Commercial Bank (NCB) for clients based in Jamaica.'
    },

    { question: 'What is your rejection fee?', answer: 'The rejection fee is 50% of the final invoice total.' }
  ];
  const general: QuestionProps[] = [
    { question: 'When was the Write Step founded?', answer: 'The Write Step was founded on August 14, 2018.' },
    { question: 'Where are you based?', answer: 'The Write Step is based in Jamaica, a small island in North America, serving clients globally.' },

    { question: 'Do you do speaking engagements?', answer: 'Definitely! Please send official booking requests to takethewritestep@gmail.com.' },
    {
      question: 'Is the Write Step involved with any charities?',
      answer: 'The Write Step is currently not involved with any charities but provides pro bono services in special situations.'
    },
    { question: 'How can I join the Write Step Team of Writers?', answer: 'Send us an email at takethewritestep@gmail.com with your resume or curriculum vitae for consideration.' }
  ];

  return (
    <>
      <ZSEO title='Got QUestions' />

      <ZHeading firstText={'Got'} secondText={'Questions?'} />

      <Container className={classes.container} maxWidth='lg'>
        <Grid container className={classes.servicesWrapper} item xs={12}>
          <Grid container justify='center' item md={2} xs={12}></Grid>
          <Grid item xs={12}>
            <Typography className={classes.servicesHeading}>SERVICE</Typography>
            {services.map((questionAnswer, i) => (
              <Grid className={classes.questionAnswerWrapper} container>
                <Typography className={classes.questionAnswerNumber}>{i + 1}.</Typography>
                <Typography className={classes.questionAnswerQuestion}>{questionAnswer.question}</Typography>
                <Grid item xs={12}>
                  <Typography className={classes.questionAnswerAnswer}>{questionAnswer.answer}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container className={classes.servicesWrapper} item xs={12}>
          <Grid container justify='center' item md={2} xs={12}></Grid>
          <Grid item xs={12}>
            <Typography className={classes.servicesHeading}>PAYMENT</Typography>
            {payment.map((questionAnswer, i) => (
              <Grid className={classes.questionAnswerWrapper} container>
                <Typography className={classes.questionAnswerNumber}>{i + 1}.</Typography>
                <Typography className={classes.questionAnswerQuestion}>{questionAnswer.question}</Typography>
                <Grid item xs={12}>
                  <Typography className={classes.questionAnswerAnswer}>{questionAnswer.answer}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>

        <Grid container className={classes.servicesWrapper} item xs={12}>
          <Grid container justify='center' item md={2} xs={12}></Grid>
          <Grid item xs={12}>
            <Typography className={classes.servicesHeading}>GENERAL</Typography>
            {general.map((questionAnswer, i) => (
              <Grid className={classes.questionAnswerWrapper} container>
                <Typography className={classes.questionAnswerNumber}>{i + 1}.</Typography>
                <Typography className={classes.questionAnswerQuestion}>{questionAnswer.question}</Typography>
                <Grid item xs={12}>
                  <Typography className={classes.questionAnswerAnswer}>{questionAnswer.answer}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      </Container>

      <ZFooter />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 60
  },
  divider: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 40
    }
  },
  sectionHeading: {
    color: '#2B9EAC',
    fontSize: 20,
    fontWeight: 700
  },
  sectionHeadingBottom: {
    borderTop: `4px solid #2B9EAC`,
    width: 50,
    marginTop: 4,
    marginBottom: 40
  },
  questionAnswerWrapper: {
    marginBottom: 20
  },
  questionAnswerNumber: {
    color: '#2B9EAC',
    fontSize: 18
  },
  questionAnswerQuestion: {
    color: '#2B9EAC',
    fontSize: 18
  },
  questionAnswerAnswer: {
    marginTop: 2,
    color: '#000',
    fontSize: 18
  },
  servicesWrapper: {
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 50
  },
  servicesHeading: {
    color: '#BE3CA4',
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 10
  },
  servicesBody: {},
  servicesItem: {
    cursor: 'pointer'
  },
  image: {
    height: 200,
    width: 200,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      marginTop: -20
    }
  },
  writerCardPaper: {
    marginLeft: 50,
    marginRight: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  writerHeading: {
    marginLeft: 50,
    marginRight: 50,
    textAlign: 'left',

    fontSize: 18,
    color: 'black',
    borderRadius: 0
  },
  writerBody: {
    fontSize: 20,
    textAlign: 'left',
    paddingBottom: 30
  },
  writerBodyLink: {
    color: '#CC29CD',
    fontWeight: 'bold'
  },
  autherImageTitleWrapper: {
    backgroundColor: '#008C9E',
    height: 65
  },
  autherImageTitle: {
    color: 'white',
    fontWeight: 700
  },
  authorImage: {
    height: 500,
    minWidth: 400
  },
  authorSpacer: {
    marginBottom: 70
  }
}));

export default PageContactUs;
