import { Container, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import React from 'react';

const FooterItem = ({ item, link }: { item: string; link: string }) => {
  const classes = useStyles();
  return (
    <Typography
      onClick={() => {
        navigate(link);
      }}
      className={classes.servicesItem}
    >
      {item}
    </Typography>
  );
};

export default function ZFooter() {
  const classes = useStyles();

  return (
    <Grid className={classes.footer}>
      <Container maxWidth='lg'>
        <Grid container item alignContent='flex-start'>
          <Grid item md={3} container justify='flex-start' alignContent='center' direction='column'>
            <Typography className={classes.title}>Services</Typography>
            <FooterItem item={'Content and Copywriting'} link='/services' />
            <FooterItem item={'Content Marketing'} link='/services' />
            <FooterItem item={'Resume Writing'} link='/services' />
            <FooterItem item={'Proofreading'} link='/services' />
          </Grid>
          <Grid item md={3} container justify='flex-start' alignContent='center' direction='column'>
            <Typography className={classes.title}>Support</Typography>
            <FooterItem item={'FAQ'} link='/faq' />
          </Grid>
          <Grid item md={3} container justify='flex-start' alignContent='center' direction='column'>
            <Typography className={classes.title}>Company</Typography>
            <FooterItem item={'Meet the team'} link='/meet-the-team' />
          </Grid>
          <Grid item md={3} container justify='flex-start' alignContent='center' direction='column'>
            <Typography className={classes.title}>Contact</Typography>
            <FooterItem item={"Let's work together!"} link='/contact-us' />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Typography align='center'>© 2018 - 2021 Take The Write Step. All Rights Reserved.</Typography>
      </Container>
    </Grid>
  );
}
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#F1F0EE',
    color: '#555',
    paddingTop: 30,
    paddingBottom: 30
  },
  title: {
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 20
    }
  },
  divider: {
    borderTop: `#e6e6e6 2px solid`,
    marginTop: 20,
    marginBottom: 20
  },
  grid: {
    padding: '50px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    color: 'white',
    marginLeft: 20
  },
  servicesItem: {
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
}));
