import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

interface Props {
  firstText: string;
  secondText: string;
}

const ZHeading: React.FC<Props> = ({ firstText, secondText }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid className={classes.headingWrapper} container>
        <Typography className={classes.headingTextFirst}>{firstText}</Typography>
        <Typography className={classes.headingTextSecond}>{secondText}</Typography>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  headingWrapper: {
    color: '#000',
    height: 180,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center'
  },
  headingTextFirst: {
    fontSize: 30,
    fontWeight: 400,
    marginRight: 8
  },
  headingTextSecond: {
    fontSize: 30,
    fontWeight: 900
  }
}));

ZHeading.defaultProps = {};

export default ZHeading;
